import React from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import {
    Col, Form, Image, Row,
} from 'react-bootstrap';
import SEO from './seo';
import useAuthForm from '../utils/auth/useAuthForm';
import BRAND_CONFIG from '../BRAND_CONFIG';
import HeaderLogo from './header/HeaderLogo';
import ToastManager from './ToastManager';

const { name, logo } = BRAND_CONFIG;

const AuthForm = ({
    title,
    ctaText,
    ctaLoadingText,
    submitType,
    instructions,
    image,
    showTitle = true,
    showRegisterInstructions = false,
}) => {
    if (submitType !== 'login' && submitType !== 'register') {
        console.error('AuthForm submitType must be one of: "login", "register".');
    }
    const {
        fields, state, onChange, onSubmit, disabled,
    } = useAuthForm();

    const isLogin = submitType === 'login';
    const alternativeText = isLogin ? (BRAND_CONFIG?.forms?.translations?.alternativeTextLogin ?? 'Don\'t have an account?') : (BRAND_CONFIG?.forms?.translations?.alternativeTextRegister ?? 'Already have an account?');
    const alternativeCtaText = isLogin ? (BRAND_CONFIG?.forms?.translations?.alternativeCtaTextLogin ?? 'Register') : (BRAND_CONFIG?.forms?.translations?.alternativeCtaTextRegister ?? 'Log In');
    const alternativeLink = isLogin ? '/register' : '/login';
    const isHybrid = BRAND_CONFIG?.vendorUUID === 'b4526bce-c554-490a-ba4a-8e7054ddd149';

    return (
        <>
            <SEO title={title} />
            <Container fluid className="page-wrapper">
                <Col />
                <ToastManager page={submitType} />
                <Col className="mt-3 sub-form-container px-3 flex-sm-fill flex--grow-1">
                    <HeaderLogo title={`${title} ${name}`} logo={logo} logoSize={100} showTitle={showTitle} />
                    {showRegisterInstructions && (
                        <div className="container">
                            <div className="row flex justify-content-center">
                                {image && <div className="col-md-4 col-6 d-flex align-items-center">
                                    <img src={image} alt="Descriptive Alt Text" className="img-fluid" />
                                </div>}
                                <div style={{ paddingLeft: '0px', paddingRight: '0px' }} className="col-md-8 mt-4 d-flex flex-column align-items-center">
                                    <ol className='custom-ol micro-font'>
                                        {instructions.map((instruction) => (
                                            <li className='mt-2' key={instruction}>{instruction}</li>
                                        ))}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    )}
                    <Form className="form-signin">
                        <Form.Group className="form-label-group mt-4">
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                                type="email"
                                id="email"
                                className="form-control"
                                required
                                value={fields.email}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <Form.Group className="form-label-group">
                            <Form.Label for="password">{BRAND_CONFIG?.forms?.translations?.password ?? 'Password'}</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                className="form-control"
                                required
                                value={fields.password}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <div className="d-grid">
                            <Button
                                onClick={() => onSubmit(submitType)}
                                disabled={disabled}
                                className="mb-3 mt-4"
                            >
                                {state.loading ? ctaLoadingText : ctaText}
                            </Button>
                        </div>
                        <p className="text-center text-danger">{state.errorMessage}</p>
                        {isLogin && (
                            <p className="micro-font text-center">
                                <Link className="toggle-btn" to="/forgot-my-password">
                                    {BRAND_CONFIG?.forms?.translations?.forgotPassword ?? 'Forgot my password'}
                                </Link>
                            </p>
                        )}
                        <p className="micro-font text-center">
                            {alternativeText}
                            {' '}
                            <Link className="toggle-btn" to={alternativeLink}>
                                {alternativeCtaText}
                            </Link>
                        </p>
                    </Form>
                </Col>
                <Row className="info-row mt-5 d-flex align-items-sm-end">
                    <Col className="footer-notes mt-4">
                        <p className="agreement micro-font">
                            {BRAND_CONFIG?.forms?.translations?.privacyPolicyPreText ?? 'By clicking'}
                            {' '}
                            <b>{ctaText}</b>
                            {' '}
                            {BRAND_CONFIG?.forms?.translations?.privacyPolicyMidText ?? 'you agree to our'}
                            {' '}
                            <a href={BRAND_CONFIG.links.privacy} target="_blank" className="toggle-btn link-btn" rel="noreferrer">
                                {BRAND_CONFIG?.forms?.translations?.privacyPolicy ?? 'Privacy Policy'}
                            </a>
                            {BRAND_CONFIG?.forms?.translations?.privacyPolicyPostText ?? '.'}
                        </p>
                        <p className="micro-font">
                            {BRAND_CONFIG?.forms?.translations?.needHelp ?? 'Need help? Visit'}
                            {' '}
                            <a className="link-btn" target="_blank" href={BRAND_CONFIG.links.support} rel="noreferrer">
                                Support Center
                            </a>
                        </p>
                        <p className="micro-font">Powered by GENFLOW</p>
                    </Col>
                </Row>

            </Container>
        </>
    );
};

export default AuthForm;
